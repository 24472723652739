import React, { Component } from "react";
import Amplify, { Auth } from 'aws-amplify';
import { Alert, Spin, Input } from 'antd';
import { userpool } from '../config.json';

export default class Register extends Component {

    constructor(props) {
        super(props);

        this.state = {
            username: "",
            email: "",
            password: "",
            firstname: "",
            lastname: "",
            gender: "",
            submittingSignUp: false,
            signedUp: false,
            hasAlert: false,
            messgae: ""
        };

        Amplify.configure({
            Auth: userpool
        });
    }

    onTextChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    onSignup = (e) => {
        const { email, password, gender } = this.state;
        this.setState({ submittingSignUp: true, hasAlert: false, messgae: '' });

        Auth.signUp({
            username: email,
            password,
            attributes: {
                email,
                name: `${this.state.firstname} ${this.state.lastname}`,
                gender
            }
        }).then(() => {
            this.setState({
                signedUp: true, hasAlert: true, submittingSignUp: false,
                messgae: "You have successfully registered. Please verify your account."
            })
        }
        ).catch(err => {
            this.setState({ submittingSignUp: false, hasAlert: true, messgae: err.message });
            console.log(err);
        });

    }

    render() {
        return (
            <div id="mid">
                <img src="./color_logo_transparent.png" className="connecteam-logo" alt="Logo"></img>
                <div className="login-content register-form">
                    <div>
                        <div className="title big-title" style={{ marginTop: "37px" }}>
                            <p>Please register to provide your feedback.<br />Every feedback matters!</p>
                        </div>
                        <div className="two-inputs">
                            <div className="field-wrap">
                                <Input type="text" name="firstname" placeholder="First name" onChange={this.onTextChange} />
                            </div>
                            <div className="field-wrap">
                                <Input type="text" name="lastname" placeholder="Last name" onChange={this.onTextChange} />
                            </div>
                        </div>
                        <Input type="text" name="email" className="input" placeholder="Business Email" onChange={this.onTextChange} />
                        <Input.Password name="password" placeholder="Create a password" className="input" onChange={this.onTextChange} />
                        <div className="submit-login">
                            {
                                !this.state.submittingSignUp &&
                                <input type="button" value="LET'S GO" className="input form-submit" onClick={this.onSignup} />
                            }
                            {
                                // if isSigning
                                this.state.submittingSignUp &&
                                <Spin size="large" />
                            }
                        </div>
                        {
                            this.state.hasAlert &&
                            <Alert
                                description={this.state.messgae}
                                type={this.state.signedUp ? 'success' : 'error'}
                                style={{ width: "80%", margin: "0 auto" }}>
                            </Alert>
                        }
                    </div>
                    <div style={separator}></div>
                    <div className="login-actions">
                        <div className="title small-title">
                            Already have an account?
                            <a href="/login"> Log in </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}
const separator = {
    margin: "18px 51px",
    height: "1px",
    background: "#f1f1f1"
}