import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import "./App.css";
import { Layout, Menu, Breadcrumb, } from "antd";
import SubmitFeedback from "./components/SubmitFeedback";
import PublicFeedback from "./components/PublicFeedback";
import Amplify, { Auth } from 'aws-amplify';
import { userpool } from './config.json';

const { Header, Content, Footer } = Layout;

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      hasAlert: false,
      signedIn: true,
      showSubmiteFeedback: false,
      showPublicFeedback: false,
      message: ""
    };

    Amplify.configure({
      Auth: userpool
    });

  }

  componentDidMount() {
    Auth.currentAuthenticatedUser().then(user => {
      console.log(user);
    }).catch(() => {
      this.setState({ signedIn: false })
    })

    if (this.state.signedIn) {
      Auth.currentSession().then(res => {
        let accessToken = res.getAccessToken();
        let canSubmitFeedback = true, canSeePublicFeedback = false;

        let grps = accessToken.payload['cognito:groups'];
        if (grps && grps.length > 0) {
          canSubmitFeedback = grps.includes('Employees');
          canSeePublicFeedback = grps.includes('Managers');
        }


        this.setState({ showSubmiteFeedback: canSubmitFeedback, showPublicFeedback: canSeePublicFeedback });
      })
    }
  }

  onLogout = () => {
    console.log(">>>>Logging out >>>>>");
    Auth.signOut().then(() => {
      this.setState({ signedIn: false })
    });
  }

  render() {

    if (!this.state.signedIn) {
      return <Redirect to="/login" />
    }


    return (
      <Router>
        <div className="App">
          <Layout className="layout">
            <Header>
              <Menu theme="dark" mode="horizontal" defaultSelectedKeys={["1"]}>
                {
                  this.state.showSubmiteFeedback &&
                  <Menu.Item key="1">
                    <Link to="/submitfeedback">Submit Feedback</Link>
                  </Menu.Item>
                }
                {
                  this.state.showPublicFeedback &&
                  <Menu.Item key="2">
                    <Link to="/publicfeedbacks">Public Feedbacks</Link>
                  </Menu.Item>
                }
                <Menu.Item key="3" onClick={this.onLogout}>
                  Log out
                </Menu.Item>
              </Menu>
            </Header>
            <Content style={{ padding: "0 50px" }}>
              <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item
                  style={{
                    fontWeight: "bold",
                    fontSize: "24px",
                    color: "#FF9900",
                  }}
                >
                  Provide Feedback to Managers - Anytime & Anywhere
                </Breadcrumb.Item>
              </Breadcrumb>
              <div className="site-layout-content">
                {
                  this.state.showSubmiteFeedback &&
                  <>
                    <Route exact path="/" render={props => { return <SubmitFeedback /> }} />
                    <Route path="/submitfeedback" component={SubmitFeedback} />
                  </>
                }
                {
                  this.state.showPublicFeedback &&
                  <>
                    <Route exact path="/" render={props => { return <PublicFeedback /> }} />
                    <Route path="/publicfeedbacks" component={PublicFeedback} />
                  </>
                }
              </div>
            </Content>

            <Footer style={{ textAlign: "center" }}>
              © 2020 Created by Malini Rao
            </Footer>
          </Layout>
        </div>
      </Router>
    );
  }
}

export default App;
