import React, { Component } from "react";
import Amplify, { Auth } from 'aws-amplify';
import { Alert, Spin, Input } from 'antd';
import { userpool } from '../config.json';
import { Redirect } from 'react-router-dom';


export default class ForgotPassword extends Component {


    constructor(props) {
        super(props);

        this.state = {
            email: '',
            code: '',
            password: '',
            submitting: false,
            hasAlert: false,
            signedIn: false,
            message: '',
            haveVerificationCode: false,
            passwordChanged: false
        };

        Amplify.configure({
            Auth: userpool
        });
    }

    onTextChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    onProceed = (e) => {
        const { email } = this.state;
        this.setState({ submitting: true, hasAlert: false, message: '' });

        Auth.forgotPassword(email,
        ).then(() => {
            this.setState({ signedIn: true, submitting: false, haveVerificationCode: true })
        }
        ).catch(err => {
            this.setState({ submitting: false, hasAlert: true, message: err.message });
            console.log(err);
        });

    }

    onChangePassword = (e) => {
        const { email, code, password } = this.state;
        this.setState({ submitting: true, hasAlert: false, message: '' });

        Auth.forgotPasswordSubmit(email, code, password).then(() => {
            this.setState({ signedIn: true, submitting: false, passwordChanged: true })
        }
        ).catch(err => {
            this.setState({ submitting: false, hasAlert: true, message: err.message });
            console.log(err);
        });
    }

    render() {

        if (this.state.passwordChanged) {
            return <Redirect to="/login" />
        }


        return (
            <div id="mid">
                <img src="./color_logo_transparent.png" className="connecteam-logo" alt="Logo"></img>
                <div className="login-content">
                    <form id="login">
                        {
                            !this.state.haveVerificationCode &&
                            <>
                                <div className="title big-title" style={{ marginTop: "37px" }}>Want to Reset your account!</div>
                                <div className="title medium-title" style={{ marginTop: "22px" }}>Please enter your email and click on Proceed</div>
                                <Input type="text" name="email" className="input" placeholder="Email" style={{ marginTop: "35px" }} onChange={this.onTextChange} />
                            </>
                        }
                        {
                            this.state.haveVerificationCode &&
                            <>
                            <br/>
                            <Alert
                                description={"Verification code has been sent to email " + this.state.email}
                                type="success"
                                style={{ width: "80%", margin: "0 auto" }}>
                            </Alert>
                            </>
                        }
                        {
                            this.state.haveVerificationCode &&
                            <>
                                <Input.Password name="password" placeholder="New Password" className="input" style={{ marginTop: "35px" }} onChange={this.onTextChange} />
                                <Input type="text" name="code" className="input" placeholder="Verification Code" style={{ marginTop: "35px" }} onChange={this.onTextChange} />
                            </>
                        }
                        <div className="submit-login">
                            {
                                !this.state.submitting && !this.state.haveVerificationCode &&
                                <input type="button" value="PROCEED" className="input form-submit" onClick={this.onProceed} />
                            }
                            {
                                !this.state.submitting && this.state.haveVerificationCode &&
                                <input type="button" value="Change Password" className="input form-submit" onClick={this.onChangePassword} />
                            }
                            {
                                this.state.submitting &&
                                <Spin size="large" style={{ marginTop: "13px" }} />
                            }
                        </div>
                        {
                            this.state.hasAlert &&
                            <Alert
                                description={this.state.message}
                                type="error"
                                style={{ width: "80%", margin: "0 auto" }}>
                            </Alert>
                        }
                    </form>
                    <div style={separator}></div>
                    <div className="login-actions">
                        <div className="title small-title">
                            Have verified account?
                            <a href="/login"> Login now </a>
                        </div>
                    </div>

                </div>
            </div>
        )

    }
}

const separator = {
    margin: "18px 51px",
    height: "1px",
    background: "#f1f1f1"
}