import React, { Component } from "react";
import Amplify, { Auth } from 'aws-amplify';
import { Alert, Spin } from 'antd';
import { userpool } from '../config.json';

export default class ConfirmAccount extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            code: "",
            submitting: false,
            hasError: false,
            confirmed: false,
            errorMessage: ""
        };

        Amplify.configure({
            Auth: userpool
        });

        Amplify.Logger.LOG_LEVEL = 'DEBUG'
    }


    onTextChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    onVerify = (e) => {
        const { email, code } = this.state;
        this.setState({ submitting: true, hasError: false, errorMessage: '' });

        Auth.confirmSignUp({
            username: email,
            code,
        }).then(() => {
            this.setState({ confirmed: true, submitting: false })
        }
        ).catch(err => {
            this.setState({ confirmed: false, hasError: true, errorMessage: err.message, submitting: false });
            console.log(err);
        });
    }

    render() {
        return (
            <div id="mid">
                <img src="./color_logo_transparent.png" className="connecteam-logo" alt="Logo"></img>
                <div className="login-content">
                    <form id="login">
                        <div className="title medium-title" style={{ marginTop: "22px" }}>Please provide your email and passcode.</div>
                        <input type="text" name="email" className="input" placeholder="Email" style={{ marginTop: "35px" }} onChange={this.onTextChange} />
                        <input type="password" name="code" className="input" placeholder="Password Code" onChange={this.onTextChange} />
                        <div className="submit-login">
                            {
                                !this.state.submitting &&
                                <input type="button" value="Verify" className="input form-submit" onClick={this.onVerify} />
                            }
                            {
                                this.state.submitting &&
                                <Spin size="large"  style={{ marginTop: "13px" }} />
                            }
                        </div>
                        {
                            this.state.hasError &&
                            <Alert
                                message="Error"
                                description={this.state.errorMessage}
                                type="error"
                                style={{ width: "80%", margin: "0 auto" }}>
                            </Alert>
                        }
                    </form>
                    <div style={separator}></div>
                    <div className="login-actions">
                        <div className="title small-title">
                            Don't have account?
                            <a href="/register"> Create one now </a>
                        </div>
                        <div className="title small-title">
                            Do you have credentials?
                            <a href="/login"> Login now </a>
                        </div>
                    </div>

                </div>
            </div>
        )
    };
}

const separator = {
    margin: "18px 51px",
    height: "1px",
    background: "#f1f1f1"
}