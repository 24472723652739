import React, { Component } from "react";
import Amplify, { Auth } from 'aws-amplify';
import { Alert, Spin, Input } from 'antd';
import { userpool } from '../config.json';
import { Redirect } from 'react-router-dom';




export default class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            submittingLogin: false,
            hasAlert: false,
            signedIn: false,
            message: ""
        };

        Amplify.configure({
            Auth: userpool
        });
    }


    onTextChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    onLogin = (e) => {
        const { email, password } = this.state;
        this.setState({ submittingLogin: true, hasAlert: false, message: '' });


        Auth.signIn({
            username: email,
            password,
        }).then(() => {
            this.setState({ signedIn: true, submittingLogin: false })

        }
        ).catch(err => {
            this.setState({ submittingLogin: false, hasAlert: true, message: err.message });
            console.log(err);
        });
    }

    render() {
        if (this.state.signedIn) {
            return <Redirect to="/" />
        }

        return (
            <div id="mid">
                <img src="./color_logo_transparent.png" className="connecteam-logo" alt="Logo"></img>
                <div className="login-content">
                    <form id="login">
                        <div className="title big-title" style={{ marginTop: "37px" }}>Welcome Back!</div>
                        <div className="title medium-title" style={{ marginTop: "22px" }}>Please login to your Launch Pad</div>
                        <Input type="text" name="email" className="input" placeholder="Email" style={{ marginTop: "35px" }} onChange={this.onTextChange}/>
                        <Input.Password name="password" placeholder="Password" className="input" onChange={this.onTextChange} />

                        <div className="submit-login">
                            {
                                !this.state.submittingLogin &&
                                <input type="button" value="LOGIN" className="input form-submit" onClick={this.onLogin} />
                            }
                            {
                                this.state.submittingLogin &&
                                <Spin size="large" style={{ marginTop: "13px" }} />
                            }
                        </div>
                        {
                            this.state.hasAlert &&
                            <Alert
                                description={this.state.message}
                                type="error"
                                style={{ width: "80%", margin: "0 auto" }}>
                            </Alert>
                        }
                    </form>
                    <div style={separator}></div>
                    <div className="login-actions">
                        <div className="title small-title">
                            Don't have account?
                            <a href="/register"> Create one now </a>
                        </div>
                        <div className="title small-title">
                            Forgot your password?
                            <a href="/forgot"> Reset password </a>
                        </div>
                    </div>

                </div>
            </div>
        )
    };
}

const separator = {
    margin: "18px 51px",
    height: "1px",
    background: "#f1f1f1"
}